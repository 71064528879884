import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import Head from "next/head";
import {GA_TRACKING_ID} from "../../appConstants";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      {/* eslint-disable-next-line @next/next/inline-script-id */}
      <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
          }}
      />
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={"/favicons/apple-touch-icon.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={"/favicons/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={"/favicons/favicon-16x16.png"}
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={"/favicons/favicon.ico"} />
        <link rel="manifest" href={"/manifest.json"} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Bridge" />
        <meta
          name="keywords"
          content="liquid staking, pstake, $pstake, cosmos, persistence, xprt, atom, eth, stkEth"
        />
        {/*Open Graph Tags*/}
        <meta content="Persistence Bridge" property="og:title" />
        <meta property="og:image" content="/og.jpg" />
        <meta property="og:description" content="Bridge for $PSTAKE" />
        {/*Twitter Tags*/}
        <meta content="Persistence Bridge" property="twitter:title" />
        <meta content="Bridge" property="twitter:description" />
        <meta content="/og.jpg" property="twitter:image" />
        <title>Persistence Bridge</title>
      </Head>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
